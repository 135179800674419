import React from "react"
import { Props } from "./props"

export const TwitterIcon: React.FC<Props> = ({ size, ...rest }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" {...rest}>
      <title>Twitter</title>
      <path
        d="M21.0411 5.5423C22.0287 4.9505 22.7855 4.0142 23.1416 2.8985C22.2199 3.4467 21.1963 3.8445 20.1077 4.0589C19.2375 3.1314 17.9946 2.5492 16.6208 2.5492C13.5356 2.5492 11.2702 5.4278 11.9668 8.4151C7.9967 8.2152 4.4778 6.3136 2.1202 3.4244C0.8687 5.5714 1.4731 8.3801 3.5998 9.8024C2.8168 9.7772 2.0795 9.5638 1.4353 9.2048C1.3839 11.4169 2.9701 13.4882 5.2676 13.9491C4.5952 14.1315 3.8588 14.1742 3.1098 14.0306C3.7172 15.9283 5.481 17.3099 7.5728 17.3487C5.5644 18.9223 3.0342 19.6257 0.5 19.3279C2.615 20.6823 5.1259 21.472 7.8231 21.472C16.6926 21.472 21.7037 13.9821 21.401 7.2634C22.3344 6.5882 23.1445 5.747 23.7848 4.7894C22.9281 5.1697 22.0074 5.4259 21.0411 5.5413Z"
        fillRule="evenodd"
        fill="currentColor"
      />
    </svg>
  )
}
