import React from "react"
import { Props } from "./props"

export const GitHubIcon: React.FC<Props> = ({ size, ...rest }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" {...rest}>
      <title>GitHub</title>
      <path
        d="M 12 1.2818 c -5.98 0 -10.83 4.85 -10.83 10.83 c 0 4.786 3.1028 8.8445 7.4068 10.2777 c 0.5415 0.0993 0.7157 -0.2346 0.7157 -0.5207 v -2.0153 c -3.0125 0.6552 -3.6398 -1.2779 -3.6398 -1.2779 c -0.4928 -1.2509 -1.203 -1.5839 -1.203 -1.5839 c -0.9837 -0.6724 0.0749 -0.6588 0.0749 -0.6588 c 1.0875 0.0767 1.6606 1.1173 1.6606 1.1173 c 0.9657 1.6552 2.5324 1.1769 3.1497 0.8998 c 0.0975 -0.6994 0.379 -1.1778 0.6886 -1.4476 c -2.4052 -0.2753 -4.934 -1.2039 -4.934 -5.3518 c 0 -1.1841 0.4242 -2.1498 1.1155 -2.9079 c -0.1128 -0.2735 -0.4837 -1.3754 0.1047 -2.8663 c 0 0 0.9097 -0.2906 2.9782 1.1101 c 0.8646 -0.2401 1.7906 -0.361 2.7111 -0.3646 c 0.9205 0.0045 1.8474 0.1245 2.7129 0.3646 c 2.0667 -1.4007 2.9755 -1.1101 2.9755 -1.1101 c 0.5893 1.4918 0.2184 2.5938 0.1065 2.8663 c 0.6949 0.7581 1.1155 1.7238 1.1155 2.9061 c 0 4.1605 -2.5333 5.0766 -4.9457 5.3446 c 0.3881 0.3357 0.7437 0.9946 0.7437 2.0054 v 2.9719 c 0 0.2888 0.1733 0.6272 0.722 0.5207 c 4.3013 -1.435 7.4005 -5.4926 7.4005 -10.2759 c 0 -5.98 -4.8491 -10.83 -10.83 -10.83 z"
        fillRule="evenodd"
        fill="currentColor"
      />
    </svg>
  )
}
